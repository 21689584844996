import Link from 'next/link';
import { PulseLoader } from 'react-spinners';
import styles from '../styles/modules/Button.module.scss';

const Button = ({
  type,
  style,
  href,
  color,
  loading,
  disabled,
  onClick,
  visibility,
  fullWidth,
  withShadow,
  inline,
  className,
  children
}) => {
  const handleOnClick = (e) => {
    if (loading || disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  if (visibility === false) return null;

  if (type === 'link')
    return (
      <Link
        className={[
          styles.bt,
          className,
          loading && styles.loading,
          disabled && styles.disabled,
          fullWidth && styles.full,
          withShadow && styles.shadow,
          inline && styles.inline,
          color && styles[color]
        ]
          .filter(Boolean)
          .join(' ')}
        role="button"
        tabIndex={0}
        style={style}
        onClick={handleOnClick}
        href={href ? href : undefined}
        passHref
      >
        <PulseLoader
          size={6}
          cssOverride={{ position: 'absolute' }}
          speedMultiplier={0.75}
          color="#FFFFFF"
          loading={loading}
        />
        <div className={styles.content}>{children}</div>
      </Link>
    );

  if (type === 'external')
    return (
      <a
        className={[
          styles.bt,
          className,
          loading && styles.loading,
          disabled && styles.disabled,
          fullWidth && styles.full,
          withShadow && styles.shadow,
          inline && styles.inline,
          color && styles[color]
        ]
          .filter(Boolean)
          .join(' ')}
        role="button"
        tabIndex={0}
        style={style}
        href={href ? href : undefined}
        target="_blank"
        onClick={handleOnClick}
        rel="noreferrer"
      >
        <PulseLoader
          size={6}
          cssOverride={{ position: 'absolute' }}
          speedMultiplier={0.75}
          color="#FFFFFF"
          loading={loading}
        />
        <div className={styles.content}>{children}</div>
      </a>
    );

  return (
    <button
      className={[
        styles.bt,
        className,
        loading && styles.loading,
        disabled && styles.disabled,
        fullWidth && styles.full,
        withShadow && styles.shadow,
        inline && styles.inline,
        color && styles[color]
      ]
        .filter(Boolean)
        .join(' ')}
      type="button"
      style={style}
      onClick={handleOnClick}
    >
      <PulseLoader
        size={6}
        cssOverride={{ position: 'absolute' }}
        speedMultiplier={0.75}
        color="#FFFFFF"
        loading={loading}
      />
      <div className={styles.content}>{children}</div>
    </button>
  );
};

Button.displayName = 'Button';
Button.defaultProps = {
  type: 'button',
  loading: false,
  disabled: false,
  visibility: true
};

export default Button;
